const getGameType = type => {
	if (type === 'group') {
		return 'Gruppenspiel';
	}
	if (type === 'grp') {
		return 'Gruppenspiel';
	}
	if (type === 'cross') {
		return 'Kreuzspiel';
	}
	if (type === 'krz') {
		return 'Kreuzspiel';
	}
	if (type === 'place') {
		return 'Platzierungsspiel';
	}
	if (type === 'plz') {
		return 'Platzierungsspiel';
	}
	if (type === 'akk') {
		return 'Außer Konkurrenz';
	}
	return '';
};

export default getGameType;