/* eslint-disable import/prefer-default-export */
import React  from 'react';
import { ApolloProvider } from '@apollo/client';
// import { isEqual } from 'lodash';
// import GET_WETTKAEMPFE from '@apolloFrontend/apicalls/GET_WETTKAEMPFE';
// import GET_CALENDAR from '@apolloFrontend/apicalls/GET_CALENDAR';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { SeasonContextProvider, UserContextProvider} from './src/provider';
import client from './src/apollo/client';

export const wrapRootElement = ({ element }) => {
	loadDevMessages();
	loadErrorMessages();
	return (
		<ApolloProvider client={client}>
			<UserContextProvider>
				<SeasonContextProvider>
					{element}
				</SeasonContextProvider>
			</UserContextProvider>
		</ApolloProvider>
	);
};



// export const onClientEntry = async () => {
// 	// Wettkämpfe
// 	if (!localStorage.getItem('WETTKAEMPFE')) {
// 		client.query({
// 			query: GET_WETTKAEMPFE,
// 			fetchPolicy: 'network-only'
// 		}).then(responseData => {
// 			localStorage.setItem('WETTKAEMPFE', JSON.stringify(responseData.data));
// 			client.writeQuery({
// 				query: GET_WETTKAEMPFE,
// 				data: responseData.data
// 			});
// 		});
// 	}

// 	const clientWkHandler =  async () => {
// 		const data = JSON.parse( localStorage.getItem('WETTKAEMPFE'));
// 		client.writeQuery({
// 			query: GET_WETTKAEMPFE,
// 			data
// 		});

// 		localStorage.setItem('WETTKAEMPFE', JSON.stringify({}));

// 		await client.query({
// 			query: GET_WETTKAEMPFE,
// 			fetchPolicy: 'network-only'
// 		}).then(responseData => {
// 			if (!isEqual( JSON.parse( localStorage.getItem('WETTKAEMPFE')), responseData.data )) {
// 				localStorage.setItem('WETTKAEMPFE', JSON.stringify(responseData.data));
// 			}
// 		});
// 	};

// 	if (localStorage.getItem('WETTKAEMPFE')) {
// 		clientWkHandler();
// 	}

// 	// Calendar
// 	if (!localStorage.getItem('CALENDAR')) {
// 		client.query({
// 			query: GET_CALENDAR,
// 			fetchPolicy: 'network-only'
// 		}).then(responseData => {
// 			localStorage.setItem('CALENDAR', JSON.stringify(responseData.data));
// 			client.writeQuery({
// 				query: GET_CALENDAR,
// 				data: responseData.data
// 			});
// 		});
// 	}
// 	if (localStorage.getItem('CALENDAR')) {
// 		const data = JSON.parse( localStorage.getItem('CALENDAR'));
// 		client.writeQuery({
// 			query: GET_CALENDAR,
// 			data
// 		});
// 		client.query({
// 			query: GET_CALENDAR,
// 			fetchPolicy: 'network-only'
// 		}).then(responseData => {
// 			if (!isEqual( JSON.parse( localStorage.getItem('CALENDAR')), responseData.data  )) {
// 				localStorage.setItem('CALENDAR', JSON.stringify(responseData.data));
// 			}
// 		});
// 	}
// 	// GAMES

	
// };