const sortGamesHandler = (games) => {
	const sortedGames =  [...games];
	sortedGames.sort((a,b) => {
		if (a.durchgang === b.durchgang) {
			return a.feld - b.feld;
		}

		if (a.durchgang !== b.durchgang) {
			return a.durchgang - b.durchgang;
		}

		return 0;
	});

	return sortedGames;
};

export default sortGamesHandler;