import axiosapi from './axios';

const createRelation = async ({className, updateObject, relationClassName, relationId, relationColumn, functions}) => {
	await axiosapi().post(`classes/${className}`, updateObject)
		.then(results => {
			axiosapi().put(`classes/${relationClassName}/${relationId}`, { [relationColumn]: { __op: 'AddRelation', objects: [{ __type: 'Relation', className, objectId: results.data.objectId }] } });
			if (functions) {
				functions(results);
			}
		});
};

export default createRelation;