import { spielklassen } from '../constants';

const spielklasseHandler = spielklasseValue => {
	let returnValue = {};
	spielklassen.forEach(spielklasse => {
		if (spielklasse.value === spielklasseValue) {
			returnValue = spielklasse;
		}
	});
	return returnValue;
};

export default spielklasseHandler;