import React, { useState , useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {AnimatePresence, motion} from 'framer-motion';
import settings from '@clientSettings';
import { StaticImage } from 'gatsby-plugin-image';
import { loaderLogo, loaderLogoAbsolute, loaderOverlay, loaderOverlayAbsolute } from './styles';


const Loader = ({loading, isFullScreen}) => {
	const [displayLoader, setDisplayLoader] = useState(false);

	useEffect(() => {
		if (typeof document !== 'undefined') {
			setDisplayLoader(true);
		}
	},[]);

	if (isFullScreen) {

		return displayLoader ? 
			ReactDOM.createPortal(
				<AnimatePresence>
					{loading === true &&(
						<>
							<motion.div
								key="loader-container"
								initial={{opacity: 0}}
								animate={{opacity: 1}}
								exit={{opacity: 0}}
								transition={{duration: 0.2}}
								css={loaderOverlay}
							/>
							<motion.div
								key='loader-logo'
								animate={{
									opacity: [1, 0.6, 0.2, 0.6, 1]
								}}
								transition={{
									duration: 2,
									ease: 'easeInOut',
									times: [0, 0.2, 0.5, 0.8, 1],
									repeat: Infinity,
									repeatDelay: 1
								}}
								css={loaderLogo}
							>
								<StaticImage
									alt='Prellball'
									src="./images/logo_mit_schrift_no_bg.png"
									height={ 60}
									layout="constrained"
								/>
								<h3 css={{color: settings.colors.primary, fontSize: '30px'}}>
									Lädt ...	
								</h3>
						
							</motion.div>
						</>)
					}
				</AnimatePresence>,
				document.body
			)
			: null;
	}

	return displayLoader ? 
		<AnimatePresence>
			{loading === true &&(
				<motion.div
					key="loader-container"
					initial={{opacity: 0}}
					animate={{opacity: 1}}
					exit={{opacity: 0}}
					transition={{duration: 0.2}}
					css={loaderOverlayAbsolute}
				>
					<motion.div
						key='loader-logo'
						animate={{
							opacity: [1, 0.8, 1, 0.8, 1]
						}}
						transition={{
							duration: 2,
							ease: 'easeInOut',
							times: [0, 0.2, 0.5, 0.8, 1],
							repeat: Infinity,
							repeatDelay: 1
						}}
						css={loaderLogoAbsolute}
					>
						{/* <img src={Logo} width='200' alt='Logo' loading='eager' /> */}
						<h3 css={{color: settings.colors.primary, fontSize: '30px'}}>
							Lädt ...	
						</h3>
						
					</motion.div>
				</motion.div>
				
			)
			}
		</AnimatePresence>
			
		: null;

};

Loader.propTypes = {
	loading: PropTypes.bool,
	isFullScreen: PropTypes.bool
};

Loader.defaultProps = {
	loading: false,
	isFullScreen: true
};

export default Loader;
