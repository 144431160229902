const loaderOverlay = {
	position: 'fixed',
	height: '100vh',
	width: '100vw',
	backgroundColor: 'rgba(0,0,0, 0.15)',
	zIndex: 12,
	top: 0,
	left: 0,
	fontFamily: 'Montserrat',
	fontWeight: 500
};


const loaderLogo ={
	position: 'fixed', 
	transform: 'translate(-50%, -50%)', 
	top: '50%', 
	left: '50%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '30px',
	zIndex: 13
};

export const loaderOverlayContainer = {
	position: 'relative',
	height: '100%',
	width: '100%',
	backgroundColor: 'rgba(0,0,0, 0.05)',
	minHeight: '360px',
	zIndex: 12,
	top: 0,
	left: 0,
	fontFamily: 'Montserrat',
	fontWeight: 500

};

const loaderOverlayAbsolute = {
	position: 'absolute',
	height: '100%',
	width: '100%',
	backgroundColor: 'rgba(0,0,0, 0.15)',
	zIndex: 13,
	top: 0,
	left: 0,
	fontFamily: 'Montserrat',
	fontWeight: 500
};

const loaderLogoAbsolute ={
	position: 'absolute', 
	transform: 'translate(-50%, -50%)', 
	top: '50%', 
	left: '50%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '30px',
	zIndex: 14
};



export {loaderOverlay, loaderLogo, loaderOverlayAbsolute, loaderLogoAbsolute};