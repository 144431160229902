const constants = {
	spielklasse :  [
		{value: 'minis', label: 'Minis', short: 'minis'},
		{value: 'wj1114', label: 'Weibliche Jugend 11-14', short: 'WJ 11-14'},
		{value: 'wj1518', label: 'Weibliche Jugend 15-18', short: 'WJ 15-18'},
		{value: 'mj1114', label: 'Männliche Jugend 11-14', short: 'MJ 11-14 '},
		{value: 'mj1518', label: 'Männliche Jugend 15-18', short: 'MJ 15-18'},
		{value: 'mlk', label: 'Männer Leistungsklasse', short: 'Männer LK'},
		{value: 'flk', label: 'Frauen Leistungsklasse', short: 'Frauen LK'},
		{value: 'f35', label: 'Frauen AK 35', short: 'Frauen 35'},
		{value: 'm30', label: 'Männer AK 30', short: 'Männer 30'},
		{value: 'm40', label: 'Männer AK 40', short: 'Männer 40'},   
		{value: 'm50', label: 'Männer AK 50', short: 'Männer 50'},   
		{value: 'm60', label: 'Männer AK 60', short: 'Männer 60'}  
	],
	transition: {'hover': {duration: .6, ease: 'easeIn'}, 'no-hover': {duration: .6, ease: 'easeOut'}}

    
};

export default constants;