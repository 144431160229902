/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { BsCalendar3 } from 'react-icons/bs';

const mainMenu = [
	{
		name: 'Bundesliga',
		category: 'Bundesliga',
		key: 'bundesliga',
		menuItems: [
			{
				name: 'Bundesliga Süd Männer',
				key: 'bl-sued-maenner',
				hasSubMenu: false,
				link: '/bundesliga/bl-sued-maenner'
			},
			{
				name: 'Bundesliga Süd Frauen',
				key: 'bl-sued-frauen',
				hasSubMenu: false,
				link: '/bundesliga/bl-sued-frauen'
			},
			{
				name: 'Bundesliga Nord Männer',
				key: 'bl-nord-maenner',
				hasSubMenu: false,
				link: '/bundesliga/bl-nord-maenner'
			},
			{
				name: 'Bundesliga Nord Frauen',
				key: 'bl-nord-frauen',
				hasSubMenu: false,
				link: '/bundesliga/bl-nord-frauen'
			},
			{
				name: 'Relegation BL Nord Männer',
				key: 'relegation-bl-nord-maenner',
				hasSubMenu: false,
				link: '/bundesliga/relegation-bl-nord-maenner'
			},
			{
				name: 'Relegation BL Nord Frauen',
				key: 'relegation-bl-nord-frauen',
				hasSubMenu: false,
				link: '/bundesliga/relegation-bl-nord-frauen'
			}
		]
	},
	{
		name: 'Regionalliga',
		category: 'Bundesliga',
		key: 'regionalliga',
		menuItems: [
			{
				name: 'Regionalliga Süd Männer',
				key: 'rl-sued-maenner',
				hasSubMenu: false,
				link: '/bundesliga/rl-sued-maenner'
			},
			{
				name: 'Regionalliga Nord Männer',
				key: 'rl-nord-maenner',
				hasSubMenu: false,
				link: '/bundesliga/rl-nord-maenner'
			},
			{
				name: 'Regionalliga Nord Frauen',
				key: 'rl-nord-frauen',
				hasSubMenu: false,
				link: '/bundesliga/rl-nord-frauen'
			}
		]
	},
	{
		name: 'Meisterschaften',
		category: 'Meisterschaften',
		key: 'meisterschaften',
		menuItems: [
			{
				name: 'Deutsche Meisterschaften',
				key: 'dm',
				hasSubMenu: false,
				link: '/meisterschaften/dm'
			},
			{
				name: 'Deutsche Jugendmeisterschaften',
				key: 'djm',
				hasSubMenu: false,
				link: '/meisterschaften/djm'
			},
			{
				name: 'Deutsche Seniorenmeisterschaften',
				key: 'dsm',
				hasSubMenu: false,
				link: '/meisterschaften/dsm'
			},
			{
				name: 'Deutschlandpokal',
				key: 'dp',
				hasSubMenu: false,
				link: '/meisterschaften/dp'
			}
		]
	},
	{
		name: 'Regionalmeisterschaften',
		category: 'Regionales',
		key: 'Regionalmeisterschaften',
		menuItems: [
			{
				name: 'Süddeutsche Jugendmeisterschaften',
				key: 'sdjm',
				hasSubMenu: false,
				link: '/regionalmeisterschaften/sdjm'
			},
			{
				name: 'Süddeutsche Seniorenmeisterschaften',
				key: 'sdsm',
				hasSubMenu: false,
				link: '/regionalmeisterschaften/sdsm'
			},
			{
				name: 'Norddeutsche Jugendmeisterschaften',
				key: 'ndjm',
				hasSubMenu: false,
				link: '/regionalmeisterschaften/ndjm'
			},
			{
				name: 'Norddeutsche Seniorenmeisterschaften',
				key: 'ndsm',
				hasSubMenu: false,
				link: '/regionalmeisterschaften/ndsm'
			}
		]
	},
	{
		name: 'Informationen',
		category: 'Info',
		key: 'informationen',
		menuItems: [
			{
				name: 'Vereine finden',
				key: 'vereine',
				hasSubMenu: false,
				link: '/information/vereine'
			},
			{
				name: 'Schwarzes Brett',
				key: 'pinboard',
				hasSubMenu: false,
				link: '/information/schwarzes-brett'
			},
			{
				name: 'Berichte und Neuigkeiten',
				key: 'aktuelles',
				hasSubMenu: false,
				link: '/information/aktuelles'
			},
			{
				name: 'Was ist Prellball',
				key: 'was-ist-prellball',
				hasSubMenu: false,
				link: '/information/was-ist-prellball'
			},
			{
				name: 'Trainingsmaterial',
				key: 'trainingsmaterial',
				hasSubMenu: false,
				link: '/information/trainingsmaterial'
			},
			{
				name: 'Dokumente und Links',
				key: 'dokumente',
				hasSubMenu: false,
				link: '/information/dokumente'
			},
			{
				name: 'Das technische Komitee',
				key: 'organisation',
				hasSubMenu: false,
				link: '/information/organisation'
			},
			{
				name: 'Newsletter',
				key: 'newsletter',
				hasSubMenu: false,
				link: '/information/newsletter'
			}
		]
	},
	{
		name: 'Kalender',
		key: 'kalender',
		link: '/kalender',
		icon: () => <BsCalendar3 />
	},
	{
		name: 'Kontakt',
		key: 'kontakt',
		link: 'mailto:medien@prellball.de',
		icon: () => <AiOutlineMail />
	}
];

export default mainMenu;