import {create} from 'zustand';
// import {createStore} from 'zustand/vanilla';
// import { createStore } from 'zustand/vanilla';

const vanillaStore = create(set => ({
	displayMessage: false,
	message: '',
	dataIsLoading: false,
	showMessage: message => set(() => ({ displayMessage: true, message })),
	hideMessage: () => set(() => ({ displayMessage: false, message: '' })),
	showLoader: () => set(() => ({ dataIsLoading: true })),
	hideLoader: () => set(() => ({ dataIsLoading: false}))
}));




const useFeedbackStore = create(set => ({
	displayMessage: false,
	message: '',
	dataIsLoading: false,
	showMessage: message => set(() => ({ displayMessage: true, message })),
	hideMessage: () => set(() => ({ displayMessage: false, message: '' })),
	showLoader: () => set(() => ({ dataIsLoading: true })),
	hideLoader: () => set(() => ({ dataIsLoading: false}))
}));


export const { getState, setState, subscribe, destroy } = vanillaStore;
export default useFeedbackStore;