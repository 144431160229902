import {colors, componentStyles, mq, functions} from './definitions';
import constants from './definitions/constants';

const settings = {
	colors,
	mq,
	padding: componentStyles.padding,
	fontSize: componentStyles.fontSize,
	borderRadius: componentStyles.borderRadius,
	sizes: componentStyles.sizes,
	shadows: componentStyles.shadows,
	functions:  {
		dateHandler: functions.dateHandler,
		slugify: functions.slugify,
		logoHandler: functions.logoHandler,
		dateToObject: functions.dateToStringHandler,
		teamclassHandler: functions.spielklasseHandler
	},
	constants: {
		spielklassen: constants.spielklasse,
		transition: constants.transition
	}
};

export default settings;