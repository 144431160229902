const gamesSortHandler = (games) => {
	const gamesArray = games.sort((a,b) => {
		if (a.spieltag?.spieltagNr < b.spieltag?.spieltagNr  ) {
			return -1;
		} 
		if (a.spieltag?.spieltagNr > b.spieltag?.spieltagNr  ) {
			return 1;
		} 
		if (a.spieltag?.spieltagNr === b.spieltag?.spieltagNr  ) {
			if (a.durchgang < b.durchgang  ) {
				return -1;
			} 
			if (a.durchgang > b.durchgang  ) {
				return 1;
			} 
			if (a.durchgang === b.durchgang) {

				if (a.feld < b.feld  ) {
					return -1;
				} 
				if (a.feld > b.feld  ) {
					return 1;
				} 
			}
		}
		return 0;
	});
	return gamesArray;
};

export default gamesSortHandler;