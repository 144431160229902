/* eslint-disable react/jsx-filename-extension */
import settings from '@clientSettings';
import React from 'react';

const getTeamInvitationStatus = teamState => {
	if (teamState === 'gemeldet') {
		return (
			<span css={{color: settings.colors.green}} >
				gemeldet 
			</span>
		);
	}
	if (teamState === 'erstellt') {
		return (
			<span css={{color: settings.colors.red}} >
				erstellt 
			</span>
		);
	}
	if (teamState === 'eingereicht') {
		return (
			<span css={{color: settings.colors.yellow}} >
				eingereicht 
			</span>
		);
	}
	if (teamState === 'eingeladen') {
		return (
			<span css={{color: settings.colors.orange}} >
				eingeladen 
			</span>
		);
	}
	if (teamState === 'abgelehnt') {
		return (
			<span css={{color: settings.colors.red}} >
				abgelehnt 
			</span>
		);
	}
	return (
		<span>
			kein Status hinterlegt
		</span>
	);
};

export default getTeamInvitationStatus;