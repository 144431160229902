import { gql, useQuery } from '@apollo/client';

const useGetSeasons = () => {
	const {data} = useQuery(gql`
		query getAllSeasonsBackend {
			objects {
				findSaison(order: value_DESC) {
					results {
						id: objectId
						objectId
						name
						value
						label
						wettkaempfe {
							results {
								objectId
								wettkampf 
							}
						}
					}
				}
			}
		}
	`);

	if (data) return ({
		data: data?.objects.findSaison.results || []
	});

	return ({data: []});
 
};

export default useGetSeasons;