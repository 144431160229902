import { gql, useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

const GET_ST = gql`
	query {
		objects {
			findEinstellungen {
				results {
					objectId
					typ 
                    einstellungen 
				}
			}
        }
    }
`;

const useGetSettings = () => {
	const client = useApolloClient();

	const [settingsData, setSettingsData] = useState([]);

	const settingsQuery =  useCallback(async () => {
		const { data } = await client.query({
			query: GET_ST,
			fetchPolicy: 'no-cache'
		});

		setSettingsData(data.objects.findEinstellungen.results);
	}, [client]);

	useEffect(() => {
		settingsQuery();
	}, [settingsQuery]);

	const settings = useMemo(() => {
		const wkSettings = [];

		if (settingsData) {
			settingsData.forEach(sd => {
				if (sd.typ === 'Wettkaempfe') {
					wkSettings.push(sd);
				}
			});
			return ({
				adminSettings :settingsData,
				wkSettings,
				settingsQuery

			});
		}
        
		return ({
			adminSettings : {},
			wkSettings,
			settingsQuery
		});

	}, [settingsData, settingsQuery]);

	return settings;
};

export default useGetSettings;