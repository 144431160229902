const getCurrentSeason = seasons => {
	const date = new Date();
	let saison = '';

	const month = date.getMonth() + 1;
	if ((month) < 7) {
		const year1 = date.getFullYear() - 1 ;
		const year2 = date.getFullYear();
		saison = `s-${  year1  }-${  year2}`;
	}
	if ((month) > 6) {
		const year1 = date.getFullYear();
		const year2 = date.getFullYear() + 1;
		saison = `s-${year1}-${year2}`;
	}

	const currentSeason = seasons.find(season => season.value === saison );
	return currentSeason;
};

export default getCurrentSeason;