const gameTypes = [
	{
		value: 'grp',
		label: 'Gruppenspiel'
	},
	{
		value: 'krz',
		label: 'Kreuzspiel'
	},
	{
		value: 'plz',
		label: 'Platzierungsspiel'
	},
	{
		value: 'ent',
		label: 'Entscheidungsspiel'
	},
	{
		value: 'akk',
		label: 'Außer Konkurrenz'
	}
];

export default gameTypes;