import { useCallback } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { gamesSortHandler } from '../functions';
import { useDataHandlers } from '../api';

const GET_GAMES = gql `
    query groupGames($wkId: WettkaempfePointer) {
        objects {
            findGames(where: {wettkampf: {_eq: $wkId}}) {
                results {
                    objectId
					id: objectId
					type
					spielNr
					durchgang
					feld
					spieltag {
						objectId
						id: objectId
						datum
						spieltagNr
						felder
					}
                }
            }
        }
    }
`; 

const useCreateGameOrder = ({wettkampfId}) => {
	const {updateData} = useDataHandlers();
	const [getGames] = useLazyQuery(GET_GAMES, {variables: {wkId: wettkampfId}, fetchPolicy: 'no-cache'});
	const gameChangeHandler= useCallback(async () => {
		const {data} = await getGames();
		const sortedGames = gamesSortHandler(data.objects.findGames.results);
		console.log(sortedGames);

		const gamesPromiseArray =  [];
		sortedGames.forEach((game, index) => { 
			if (game.spielNr !== index + 1)
				updateData({
					className: 'Games',
					objectId: game.objectId,
					updateObject: {spielNr: index + 1}
				});
		});

		await Promise.all(gamesPromiseArray);

	}, [getGames]);

	return gameChangeHandler;
  
};

export default useCreateGameOrder;