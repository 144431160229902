export const trainingsklassen = [
	{value: 'minis', label: 'Minis'},
	{value: 'wj1114', label: 'Weibliche Jugend 11-14'},
	{value: 'wj1518', label: 'Weibliche Jugend 15-18'},
	{value: 'mj1114', label: 'Männliche Jugend 11-14'},
	{value: 'mj1518', label: 'Männliche Jugend 15-18'},
	{value: 'mlk', label: 'Männer Leistungsklasse'},
	{value: 'flk', label: 'Frauen Leistungsklasse'},
	{value: 'mak', label: 'Männer Altersklasse'},
	{value: 'fak', label: 'Frauen Altersklasse'},
	{value: 'mag', label: 'Männer Allgemein'},
	{value: 'fag', label: 'Frauen Allgemein'},
	{value: 'fz', label: 'Freizeit'},
	{value: 'zweier', label: 'Zweier Prellball'}
];

export default trainingsklassen;