import axios from 'axios';
import Cookies from 'js-cookie';

const axiosapi = propToken => {
	const localToken = Cookies.get('st');
	const token = localToken || propToken;
	return axios.create({
		baseURL: 'https://pg-app-kku833xcn4w1y2449ka9igu9zdxyck.scalabl.cloud/1/',
		headers: {
			'X-Parse-Application-Id': '5PVu925c8ALhacXT0Pu6zcrESSMYgzDrcQTSQQWr',
			'X-Parse-REST-API-Key': 'Zd8cUhY2swUbzorKit38ubhUSSQMPSQzrDOcWRNr',
			// "X-Parse-Master-Key": "rxUSkk7nCOzpxz4vcVonPrIS3WcrDRMUQSSQzPMO",
			'X-Parse-Session-Token': token
		}
	});
};

export default axiosapi;
