const colors = {
	medium: '#7A38FB',
	dark: '#0a0417',
	light: '#f3f3f4',
	lightgrey: '#dcdce0',
	white: '#fff',
	grey:'#c4c4c4',
	darkgrey: '#8e8e93',

	primary: '#6839DB',
	primaryShaded: '#451ea3',
	secondary: '#39DB9E',
	secondaryShaded: '#1ea371',
	tertiary: '#ed0330',
	tertiaryShaded: '#be0226',
	
	purple: '#6839DB',
	red: '#DB0C1C',
	green: '#8BE836',
	blue: '#5793FF',
	yellow: '#FFC142',
	orange: '#FF7326',
	lightblue: '#88F7E2',
	lightgreen: '#88F7E2',
	lightred: '#f2282d',
	lightyellow: '#F5EB67',
	lightpurple: '#D44DFF',
	darkpurple: '#4D0066'
};

export default colors;