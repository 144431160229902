const spielklassen =[
	{value: 'minis', label: 'Minis', short: 'Minis'},
	{value: 'wj1114', label: 'Weibliche Jugend 11-14', short: 'WJ 11-14'},
	{value: 'wj1518', label: 'Weibliche Jugend 15-18', short: 'WJ 15-18'},
	{value: 'mj1114', label: 'Männliche Jugend 11-14', short: 'MJ 11-14 '},
	{value: 'mj1518', label: 'Männliche Jugend 15-18', short: 'MJ 15-18'},
	{value: 'mlk', label: 'Männer Leistungsklasse', short: 'Männer LK'},
	{value: 'flk', label: 'Frauen Leistungsklasse', short: 'Frauen LK'},
	{value: 'f35', label: 'Frauen AK 35', short: 'Frauen 35'},
	{value: 'f30', label: 'Frauen AK 30', short: 'Frauen 30'},
	{value: 'f40', label: 'Frauen AK 40', short: 'Frauen 40'},
	{value: 'm30', label: 'Männer AK 30', short: 'Männer 30'},
	{value: 'm40', label: 'Männer AK 40', short: 'Männer 40'},   
	{value: 'm50', label: 'Männer AK 50', short: 'Männer 50'},   
	{value: 'm60', label: 'Männer AK 60', short: 'Männer 60'},
	{value: 'fz', label: 'Freizeit', short: 'Fz'},
	{value: 'mixed', label: 'Mixed', short: 'Mix'}    
];

export default spielklassen;