import { gql, useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

const GET_WK = gql`
	query wettkaempfe {
		objects {
			findWettkaempfe {
				results {
					objectId
					id: objectId
					slug
					titel
                    kategorie
					saison {
						objectId
						id: objectId
						value
						label
					}
				}
			}
        }
    }
`;

const useGetSortedWettkaempfe = () => {
	const client = useApolloClient();
	const [wkData, setWkData] = useState([]);
	
	const wkQuery =  useCallback(async () => {

		const { data } = await client.query({
			query: GET_WK,
			fetchPolicy: 'cache-first'
		
		});
		
		setWkData(data.objects.findWettkaempfe.results);

	}, [client]);	

	useEffect(() => {
		wkQuery();
	}, [wkQuery]);

	const wettkaempfe =  useMemo(() => {
		const bl = [];
		const ms = [];
		const rm = [];
		const tr = [];

		if (wkData) {
			wkData.forEach(wk => {
				if (wk.kategorie === 'Bundesliga') {
					bl.push(wk);
				}
				if (wk.kategorie === 'Meisterschaften') {
					ms.push(wk);
				}
				if (wk.kategorie === 'Regionalmeisterschaften') {
					rm.push(wk);
				}
				if (wk.kategorie === 'Turniere') {
					tr.push(wk);
				}
			});

			return ({
				bl,
				ms,
				rm,
				tr,
				wkQuery,
				dataHasLoaded: true
			});
		}
        
		return ({
			bl,
			ms,
			rm,
			tr,
			wkQuery,
			dataHasLoaded: false

		});

	}, [wkData, wkQuery]);

	return wettkaempfe;
};

export default useGetSortedWettkaempfe;