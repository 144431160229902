const { default: settings } = require('@settings');

const categoryColorHandler = category => {
	if (category === 'Meisterschaften') {
		return ({
			mainColor: settings.colors.category_1,
			fontColor: settings.colors.dark
		});
	}
	if (category === 'Regionales' || category === 'Regionalmeisterschaften') {
		return ({
			mainColor: settings.colors.category_2,
			fontColor: settings.colors.dark
		});
	}
	if (category === 'Bundesliga') {
		return ({
			mainColor: settings.colors.category_3,
			fontColor: settings.colors.white
		});
	}
	if (category === 'Info') {
		return ({
			mainColor: settings.colors.category_4,
			fontColor: settings.colors.white
		});
	}
    
	return ({
		mainColor: settings.colors.primary,
		fontColor: settings.colors.dark
	});
    
};

export default categoryColorHandler;
