import useGetSeasons from '@apolloBackend/hooks/useGetSeasons';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import getCurrentSeason from './functions/getCurrentSeason';
import SeasonContext from './SeasonContext';
import SeasonSelect from './components/SeasonSelect';

const SeasonContextProvider = ({children}) => {
	const { data: seasons } = useGetSeasons();
	const [season, setSeason] = useState();

	useEffect(() => {
		let cleanup =false;
		if (!cleanup) {
			if (seasons) {
				setSeason(getCurrentSeason(seasons));
			}
		}
		return () => {
			cleanup = true;
		};
	}, [seasons]);

	const getSeasonsForChampionship = (wkName) => {
		const seasonsArray = [];
		seasons.forEach(seasonToReturn => {
			seasonToReturn.wettkaempfe.results.forEach(wk => {
				if (wk.wettkampf === wkName) {
					seasonsArray.push(seasonToReturn);
				}
			});

		});

		return seasonsArray;
	};

	const seasonContextObject = useMemo(() => ({
		season,
		seasons,
		getSeasonsForChampionship,
		SeasonSelect: <SeasonSelect seasons={seasons} setSeason={setSeason} season={season}  />
	}), [season, seasons]);

	return (
		<SeasonContext.Provider
			value={seasonContextObject}
		>
			{children}
		</SeasonContext.Provider>
	);
};

SeasonContextProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default SeasonContextProvider;