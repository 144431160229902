import {colors, componentStyles, constants, mq} from './definitions';

const settings = {
	colors,
	mq,
	padding: componentStyles.padding,
	fontSize: componentStyles.fontSize,
	borderRadius: componentStyles.borderRadius,
	sizes: componentStyles.sizes,
	shadows: componentStyles.shadows,
	constants

};

export default settings;