exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-information-aktuelles-jsx": () => import("./../../../src/pages/information/aktuelles.jsx" /* webpackChunkName: "component---src-pages-information-aktuelles-jsx" */),
  "component---src-pages-information-archiv-jsx": () => import("./../../../src/pages/information/archiv.jsx" /* webpackChunkName: "component---src-pages-information-archiv-jsx" */),
  "component---src-pages-information-dokumente-jsx": () => import("./../../../src/pages/information/dokumente.jsx" /* webpackChunkName: "component---src-pages-information-dokumente-jsx" */),
  "component---src-pages-information-faq-jsx": () => import("./../../../src/pages/information/faq.jsx" /* webpackChunkName: "component---src-pages-information-faq-jsx" */),
  "component---src-pages-information-newsletter-jsx": () => import("./../../../src/pages/information/newsletter.jsx" /* webpackChunkName: "component---src-pages-information-newsletter-jsx" */),
  "component---src-pages-information-organisation-jsx": () => import("./../../../src/pages/information/organisation.jsx" /* webpackChunkName: "component---src-pages-information-organisation-jsx" */),
  "component---src-pages-information-schwarzes-brett-jsx": () => import("./../../../src/pages/information/schwarzes-brett.jsx" /* webpackChunkName: "component---src-pages-information-schwarzes-brett-jsx" */),
  "component---src-pages-information-trainingsmaterial-jsx": () => import("./../../../src/pages/information/trainingsmaterial.jsx" /* webpackChunkName: "component---src-pages-information-trainingsmaterial-jsx" */),
  "component---src-pages-information-vereine-jsx": () => import("./../../../src/pages/information/vereine.jsx" /* webpackChunkName: "component---src-pages-information-vereine-jsx" */),
  "component---src-pages-information-was-ist-prellball-jsx": () => import("./../../../src/pages/information/was-ist-prellball.jsx" /* webpackChunkName: "component---src-pages-information-was-ist-prellball-jsx" */),
  "component---src-pages-kalender-jsx": () => import("./../../../src/pages/kalender.jsx" /* webpackChunkName: "component---src-pages-kalender-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/BlogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-championship-template-jsx": () => import("./../../../src/templates/ChampionshipTemplate.jsx" /* webpackChunkName: "component---src-templates-championship-template-jsx" */)
}

