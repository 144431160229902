/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react';
import {useShallow} from 'zustand/react/shallow';
import axiosapi from './axios';
import useFeedbackStore from './useFeedbackStore';
import netlifyHook from './netlifyHook';

const useDataHandlers = () => {
	const showMessage = useFeedbackStore(useShallow(store => store.showMessage));
	const hideLoader = useFeedbackStore(useShallow(store => store.hideLoader));
	const showStoreLoader = useFeedbackStore(useShallow(store => store.showLoader));

	const deleteData = useCallback(async ({className, objectId, functions, feedback, showLoader, useNetlifyHook}) => {
		if (showLoader) {
			showStoreLoader();
		}
		await axiosapi().delete(`classes/${className}/${objectId}`)
			.then(results => {
				if (feedback) {
					showMessage(feedback);
				}
				if (showLoader) {
					hideLoader();
				}
				if (useNetlifyHook) {
					netlifyHook();
				}
				if (functions) {
					functions(results);
				}
			});
	}, []);

	const updateData = useCallback(async ({className, objectId, updateObject, functions, feedback, showLoader, useNetlifyHook}) => {
		if (showLoader) {
			showStoreLoader();
		}
        
		await axiosapi().put(`classes/${className}/${objectId}`, updateObject)
			.then(results => {
				if (feedback) {
					showMessage(feedback);
				}
				if (showLoader) {
					hideLoader();
				}
				if (useNetlifyHook) {
					netlifyHook();
				}
				if (functions) {
					functions(results);
				}
			})
            
			.catch(error => console.error(error));
	}, []);

	const createData = useCallback(async ({className, updateObject, functions, showLoader, feedback, useNetlifyHook}) => {
		if (showLoader) {
			showStoreLoader();
		}
		await axiosapi().post(`/classes/${className}`, updateObject)
			.then(results => {
				if (feedback) {
					showMessage(feedback);
				}
				if (showLoader) {
					hideLoader();
				}
				if (useNetlifyHook) {
					netlifyHook();
            
				}
				if (functions) {
					functions(results);
				}
			});
	}, []);

	const storeHandlers = useMemo(() => ({
		deleteData,
		updateData,
		createData
	}), []);
    
	return storeHandlers;
};

export default useDataHandlers;