import React from 'react';
import PropTypes from 'prop-types';
import settings from '@clientSettings';
import styled from '@emotion/styled';

const Label = ({htmlFor, label}) => {
	const StyledLabel = styled.label({
		fontFamily: 'Montserrat',
		textTransform: 'uppercase',
		lineHeight: 1.4,
		letterSpacing: '1px',
		fontWeight: 600,
		fontSize: settings.fontSize.small,
		display: 'inline-block'
	});

	return (
		<StyledLabel htmlFor={htmlFor}>
			{label}
		</StyledLabel>
	);
};

Label.propTypes = {
	htmlFor: PropTypes.string,
	label: PropTypes.string
};
Label.defaultProps = {
	htmlFor: '',
	label: ''
};

export default Label;