import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { navigate } from 'gatsby';
import FlexBox from '@clientComponents/layout/FlexBox';
import settings from '@clientSettings';
import { AiOutlineLogin } from 'react-icons/ai';
import Loader from '@clientComponents/surfaces/Loader';
import UserContext from './UserContext';

const GET_SESSION = gql`
	query session($sessionToken: String) {
		objects {
			find_Session(
			where: { sessionToken: { _eq: $sessionToken } }
			) {
				results {
					objectId
					user {
						objectId
						username
						email
						portrait
						rolle {
							name
							objectId
						}
						zugriffe {
							results {
								objectId
								inhalt
								typ
								wettkampf {
									objectId
									titel
									slug
									kategorie
									saison {
										objectId
										value
										label
									}
								}
								verein {
									objectId
									name
									slug
								}
								ablaufdatum
							}
						}
					}
				}
			}
		}
	}
`;

const LOGOUT_USER = gql`
	mutation logOut {
		users {
			logOut
		}
	}
`;

const LOGIN_USER = gql` 
	mutation logIn($password: String!, $username: String!) {
		users {
			logIn(username: $username, password: $password){
				username
				email
				sessionToken
			}
		}
	}
`;

function useLocalUser (key, initialValue) {
	const [storedUser, setStoredUser] = useState(() => {
		try {
			const item = window !== undefined ? window.localStorage.getItem(key) : '';
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			return initialValue;
		}
	});

	const setUser = value => {
		try {
			const userToStore = value instanceof Function ? value(storedUser) : value;

			setStoredUser(userToStore);

			window.localStorage.setItem(key, JSON.stringify(userToStore));
		} catch (error) {
			navigate('/login');
		}
		return null;
	};
	return [storedUser, setUser];
}

const UserDisplay = ({user}) => {
	const unreadMessages = [];

	return user ? 
		<div
			role='button'
			aria-hidden="true"
			tabIndex={0}
			onClick={() => navigate('/app/profile/')}
			css={{cursor: 'pointer'}}
		>
			<FlexBox align='center' gap={settings.sizes.medium} >
				<div>
					<h4 css={{margin: 0, textAlign:' right', fontSize: '12px', fontWeight: 500, textTransform: 'uppercase'}}>{user.username}</h4>
				</div>
				<div css={{position: 'relative'}}>
					{user.portrait?.uuid ?
						<img alt={user.username} src={`https://ucarecdn.com/${user.portrait.uuid}/-/scale_crop/200x200/smart/`} css={ {borderRadius: '50%',objectFit: 'cover', height: '2.2rem'}} />
						:
						<div css={{borderRadius: '50%',background: settings.colors.grey, width: '1.8rem', height: '1.8rem'}} />
					}
					{unreadMessages.length > 0 && 
						<div css={{position: 'absolute', top: 0, right: 0, height: '14px', width: '14px', borderRadius: '50%', backgroundColor: settings.colors.red}}>
							<div css={{position: 'absolute', fontSize: settings.sizes.medium, top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: settings.colors.white}}>
								{unreadMessages.length}					
							</div>
						</div>
					}
				</div>
			</FlexBox>
		</div>
		: 
		<a href="/login" css={{ fontSize: '1.2rem' }}>
			<AiOutlineLogin />{' '}
		</a>;

};

const UserContextProvider = ({ children }) => {
	const token = Cookies.get('st');
	const [storedUser, setUser] = useLocalUser('pb_user', null);
	
	// get user
	const [getSession, { data: userData }] = useLazyQuery(GET_SESSION, {
		fetchPolicy: 'no-cache'
	});

	const afterLoginHandler = useCallback(async (user) => {
		Cookies.set('st', user.sessionToken, { expires: 90});
		await getSession({variables: { sessionToken: user.sessionToken }}).then(results => {
			setUser(results.data.objects.find_Session.results[0].user);
			setTimeout(() => {
				navigate('/app/profile');
			}, [300]);
		});
	}, []);

	// login
	const [userLogin, { loading: loginLoading }] = useMutation(LOGIN_USER,
		{
			fetchPolicy: 'no-cache',
			onError: loginError => { 
				if (loginError.message === 'Invalid username/password.') {
					window.alert('Falsche E-Mail / Passwort Kombination');
				}
			},
			onCompleted: data => afterLoginHandler(data.users.logIn)
		}) ;

	// logout
	const [userLogout] = useMutation(LOGOUT_USER, {
		fetchPolicy: 'no-cache',
		onCompleted: () => {
			setUser(null);
			Cookies.remove('st');
			navigate('/login');
		}
	});

	useEffect(() => {
		const userHandler = async () => {
			await getSession({variables: { sessionToken: token }}).then(results => {
				setUser(results.data.objects.find_Session.results[0].user);
				setTimeout(() => {
					navigate('/app/profile');
				}, [300]);
			});
		};
		if (!token && storedUser) {
			userLogout();
		}
	
	  if (storedUser === null && token) {
			userHandler();
	  }
	}, [storedUser]);

	const updateUserData = useCallback(async () => {
		await getSession({variables: { sessionToken: token }}).then(results => {
			setUser(results.data.objects.find_Session.results[0].user);
		});
	}, [token]);
	

	

	

	const userContextObject = useMemo(() => ({
		storedUser,
		logout: () => {
			userLogout(token);
		},
		login: (object) => {
			userLogin(object);
		},
		isLoggedIn: () => {
			if (storedUser) return true;
			return false;
		},
		updateLocalUserData: updateUserData,
		userDisplay: <UserDisplay user={storedUser} />
	}), [token, userLogin, userLogout, storedUser]);

	const getSessionHandler = useCallback(async () => {
		await getSession({variables: { sessionToken: token }});
		const dataObject = await userData;
		if (dataObject && dataObject.objects.find_Session.results[0]) {
			setUser(dataObject.objects.find_Session.results[0].user);
		} 
		 if (!storedUser && token) {
			Cookies.remove('st');
		} 
	}, [token, storedUser, userData]);

	useEffect(() => {
		let cleanup = false;
		if (!cleanup) {
			getSessionHandler();
		}
		return () => {
			cleanup = true;
		};
	}, []);

	return (
		<UserContext.Provider
			value={userContextObject}
		>
			{children}
			<Loader loading={loginLoading} />
		</UserContext.Provider>
	);
};

UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired
};

UserDisplay.propTypes = {
	user: PropTypes.object
	// unreadMessages: PropTypes.array
};
UserDisplay.defaultProps = {
	user: {}
	// unreadMessages: []
};

export default UserContextProvider;
