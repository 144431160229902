import Select from '@clientComponents/form/Select';
import React from 'react';
import PropTypes from 'prop-types';

const SeasonSelect = ({setSeason, seasons, season}) => 
	<Select
		label='Saison'
		labelBefore
		width='200px'
		onChange={value => setSeason(value)}
		options={seasons || []}
		value={season}
		isClearable={false}
	/>;

SeasonSelect.propTypes = {
	setSeason: PropTypes.func.isRequired,
	seasons:PropTypes.array,
	season: PropTypes.object
};

SeasonSelect.defaultProps = {
	seasons: [],
	season: {}
};

export default SeasonSelect;