const colors = {
	primary: '#CFF542',
	primaryShaded: '#b3d226',
	secondary: '#07485B',
	secondaryShaded: '#063a49',
	tertiary: '#db396d',
	tertiaryShaded: '#360e41',
	quatiary: '#00D9C0',
	quatiaryShaded: '#00ae9a',

	category_1: '#CFF542',
	category_2: '#00D9C0',
	category_3: '#E82042',
	category_4: '#CEA9FF',

	dark: '#22262B',
	light: '#f9f9f9',
	white: '#ffffff',

	grey: '#dddddd',
	lightgrey: '#f3f3f4',
	darkgrey: '#8e8e93'
};

export default colors;

