import { gql, useQuery } from '@apollo/client';

const useGetClub = (id) => {
	const {data} = useQuery(gql`
		query getTeam {
            objects {
                findVereine(order: name_DESC) {
                    results {
                        name
                        objectId
                        logo
                    }
                }
			}
		}
	`);
	if (data) {
		return data.objects.findVereine.results.find(verein => verein.objectId === id);
	}
	return null;
};

export default useGetClub;